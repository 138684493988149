<template>
  <div>
    <button @click="emitClick()" :type="type" class="g-btn" :class="{
      'gbtn-longer':this.icon, 
      'gbtn-grey': (this.color == 'grey' || this.color == 'gray') ,
      'gbtn-orange': this.color == 'orange',
      'gbtn-red': this.color == 'red',
      'gbtn-block': this.block,
      'gbtn-smaller': this.smaller
    }"
    >{{ this.title }} <span v-if="this.icon" class="gfl" :class="this.icon" ></span></button>
  </div>
</template>

<script>

//  
export default {
  name: 'gbtn',
  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    icon: {
      type: String,
      default: "",
      required: false
    },
    color: {
      type: String,
      default: "",
      required: false
    },
    block: {
      type: Boolean,
      default: false,
      required: false
    },
    smaller: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: "button",
      required: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    emitClick() {
      this.$emit('click')
    }
  }
}
</script>

<style>

</style>
