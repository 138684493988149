import api from "../config";
const path = "user"

export default {
  get: () => {
    return api.get(`${path}`)
  },
  getCurrent:() => {
    return api.get(`${path}/current/`)
  },
  changePassword: (oldPw, newPw1, newPw2) => {
    let params = new URLSearchParams()
    params.append("oldpw", oldPw)
    params.append("newpw1", newPw1)
    params.append("newpw2", newPw2)
    return api.post("user/change_pw/",params)
  },
  changePersonal: (salutation, name, surname) => {
    let params = new URLSearchParams()
    params.append("salutation", salutation)
    params.append("name", name)
    params.append("surname", surname)
    return api.post("user/change_personal/",params)
  }
}
