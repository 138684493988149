import api from "../config";

export default {
  add: (card) => {
    let params = new URLSearchParams()
    params.append("card", card)
    return api.post("card/add/",params)
  },
  share: (card, mail, date) => {
    let params = new URLSearchParams()
    params.append("card", card)
    params.append("mail", mail)
    params.append("date", date)
    return api.post("card/share/",params)
  },
  remove: (card) => {
    let params = new URLSearchParams()
    params.append("card", card)
    return api.post("card/remove/",params)
  },
  shareRemove: (card) => {
    let params = new URLSearchParams()
    params.append("card", card)
    return api.post("card/remove_shares/",params)
  },
}
