

import VueRouter from "vue-router";
import VueMeta from 'vue-meta';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
/**
 * Setup Plugins for the Vue instance
 * @param {VueConstructor<Vue>} instance
 */
export function setupPlugins (instance) {
  instance.use(VueRouter);

  instance.use(BootstrapVue);

  instance.use(IconsPlugin)

  instance.use(VueMeta, { refreshOnceOnNavigation: true });
}
