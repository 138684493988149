//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from "axios";

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages : {}// set locale messages
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  
  return lang
}

// QuickFix-Function, to be exchanged by dynamic mapping
function setRtlState(lang) {
 if (lang=="ar" || lang=="fa") {
   document.querySelector('html').setAttribute('dir', 'rtl')
 } else {
   document.querySelector('html').setAttribute('dir', 'ltr')
 }
}

export function loadLanguageAsync(lang, force = false) {
  // If the same language
  if (i18n.locale === lang && !force) {
    // quick fix for RTL/LTR-changes on already loaded languages
    setRtlState(lang)
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    // quick fix for RTL/LTR-changes on already loaded languages
    setRtlState(lang)
    return Promise.resolve(setI18nLanguage(lang))
  }

  return axios.get(`${process.env.VUE_APP_SERVER_URL}/static/cdn/i18n/locales/${lang}.json`).then(response => {
  //return axios.get(`${process.env.VUE_APP_SERVER_URL}/languages/v1/language/?locale=${lang}`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, msgs)
    setI18nLanguage(lang)
    document.querySelector('html').setAttribute('dir', msgs.language.direction)
  });
}
