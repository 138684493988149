<template>
    <div class="gera-card"> 
        <img class="gera-card-graphics" src="@/assets/card_background.png"/>
        <div class="gera-card-title">Gera-Pass</div>
        <div v-if="!(this.topinfo == '' || this.topinfo == null)" class="gera-card-topinfo">{{ this.topinfo }}</div>
        <div class="gera-card-name">{{ this.name }}</div>
        <div class="gera-card-birthday">geb. {{ retBirthDate(this.birthday) }}</div>
        <div class="gera-card-inner">
          <div class="gera-card-icon"><span class="gfl" :class="{'gf-check':this.isvalid,'gf-warning':!this.isvalid}"></span></div>
          <div class="gera-card-validity">
            Validiert am <span>{{ retValidatedDate(this.validated) }}</span>
            <br/>
           {{ retValidDate(this.valid) }} - <span :class="{'green-text':this.isvalid,'red-text':!this.isvalid}">{{ this.isvalid?"Gültig":"Ungültig" }}</span>
          </div>
          <div class="gera-card-separator"></div>
          <div class="gera-card-qrcode"><qrcode-vue render-as="svg" :value="this.qrcode" size="200"></qrcode-vue></div>
        </div>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'pass',
  components: {QrcodeVue},
  props: {
    topinfo: {
      type: String,
      default: "",
      required: false
    },
    name: {
      type: String,
      default: "",
      required: true
    },
    birthday: {
      type: String,
      default: "",
      required: true
    },
    validated: {
      type: String,
      default: "",
      required: true
    },
    valid: {
      type: String,
      default: "",
      required: true
    },
    isvalid: {
      type: Boolean,
      default: false,
      required:true
    },
    qrcode: {
      type: String,
      default: "",
      required: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    retBirthDate(val) {
      let dt = new Date(val);
      return dt.toLocaleDateString();
    },
    retValidatedDate(val) {
      let dt = new Date(val);
      return dt.toLocaleDateString();
    },
    retValidDate(val) {
      let dt = new Date(val);
      let mnvar = "";
      let mn = dt.getMonth()+1;
      switch (mn) {
        case 1: mnvar="Januar"; break;
        case 2: mnvar="Februar"; break;
        case 3: mnvar="März"; break;
        case 4: mnvar="April"; break;
        case 5: mnvar="Mai"; break;
        case 6: mnvar="Juni"; break;
        case 7: mnvar="Juli"; break;
        case 8: mnvar="August"; break;
        case 9: mnvar="September"; break;
        case 10: mnvar="Oktober"; break;
        case 11: mnvar="November"; break;
        case 12: mnvar="Dezember"; break;
      }
      return mnvar + ' ' + dt.getFullYear();
    }
  }
}
</script>

<style>

</style>
