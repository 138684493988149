<template>
  <div class="locale-changer">
    <span class="lng-sel" v-for="(lang, i) in langs" :key="`Lang${i}`" @click="setLocale(lang)">
        {{ lang }}
    </span>
  </div>
</template>

<script>

import { loadLanguageAsync } from "@/i18n-setup";

export default {
  name: 'locale-changer',
  data () {
    return { langs: ['DEU', 'ENG', 'RUS', 'ARA', 'FAS'] }
  },
  methods: {
    setLocale(val) {
      switch (val) {
        case 'DEU': loadLanguageAsync('de'); break;
        case 'ENG': loadLanguageAsync('en'); break;
        case 'RUS': loadLanguageAsync('ru'); break;
        case 'ARA': loadLanguageAsync('ar'); break;
        case 'FAS': loadLanguageAsync('fa'); break;
      }
      
    }
  }
}
</script>

<style>

</style>
