export const UPDATE_USER = "update_user"
export const LOGIN_START = "login_start"
export const LOGIN_STOP = "login_stop"
export const REGISTER_START = "register_start"
export const REGISTER_STOP = "register_stop"
export const REGISTER_UPDATE = "register_update"
export const REGISTERED = "registered"
export const REGISTER_FINISHED_ERROR = "register_finished_error"
export const REGISTER_FINISHED_SUCCESS = "register_finished_success"
export const RESET_FINISHED_ERROR = "reset_finished_error"
export const RESET_FINISHED_SUCCESS = "reset_finished_success"
export const RESET_VALUES = "reset_values"