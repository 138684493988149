<template>
  <div id="app">
    <locale-changer/>
    <navbar/>
    <b-container>
      <router-view/>
    </b-container>
    <b-container>
      <div class="row">
        <div class="col-12" style="text-align:right;">
          <a href="https://www.gera.de" target="_blank"><img id="gerawappen" src="@/assets/gerawappen.png"/></a>
        </div>
      </div>
    </b-container>
    <div id="footer-space"></div>
  </div>
</template>

<script>
import navbar from "@c/Navbar"
import LocaleChanger from "@c/LocaleChanger.vue"

export default {
  components: {
    navbar,
    LocaleChanger
  },
  created() {
    if(this.$route.name === "index") {
      this.$router.replace({ name: "cards"})
    }
  }
}
</script>

<style lang="scss">
@import "./styles/scss/index.scss";

#gerawappen {
  margin-top:50px;
  width:200px;
  height:69px;
}
</style>
