import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


import { setupPlugins } from "./plugins/setup"
import { i18n, loadLanguageAsync } from "./i18n-setup"

import { LOAD_USER } from "@st/session/actions.type";

// Plugins

setupPlugins(Vue);


Vue.config.productionTip = false

loadLanguageAsync('de', true).then(
  store.dispatch(`session/${LOAD_USER}`).finally(() => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })
)
