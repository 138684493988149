<template>
    <div>
        <b-container class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <b-row class="nav-row">
                <b-col>
                    <img id="gera-logo" src="@/assets/logo.png" @click="toLogin()"/>
                    <div id="mobile-menu">
                        <span class="gfl gf-burger-menu" v-b-toggle.collapse-mobilemenu></span>                        
                        <b-collapse is-nav id="collapse-mobilemenu" class="mt-2 mb-5">

                                <router-link :to="{name: 'cards'}">
                                    <div class="mob-nav" v-b-toggle.collapse-mobilemenu>{{ $t('navigation.cards') }}</div>
                                </router-link>

                                <div class="mob-nav-divider"></div>

                                <router-link :to="{name: 'change-password'}">
                                    <div class="mob-nav" v-b-toggle.collapse-mobilemenu>{{ $t('navigation.change_password') }}</div>
                                </router-link>

                                <router-link :to="{name: 'change-data'}">
                                    <div class="mob-nav" v-b-toggle.collapse-mobilemenu>{{ $t('navigation.personal_data') }}</div>
                                </router-link>

                                <div @click="logout()">
                                    <div class="mob-nav" v-b-toggle.collapse-mobilemenu>{{ $t('navigation.logout') }}</div>
                                </div>

                        </b-collapse>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <b-row class="nav-row">
                <b-col cols="4" md="3" lg="3" sm="6">
                    <img id="gera-logo" src="@/assets/logo.png" @click="toLogin()"/>
                </b-col>
                <b-col v-if="false" cols="6" md="3" lg="3" sm="6" class="d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none usermenu-container">
                    <div class="usermenu">
                        <span class="gfl gf-account"></span>
                        <br/>
                        {{ user_firstname() }}
                    </div>
                </b-col>
                <b-col cols="8" md="9" lg="9" sm="6" class="d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <b-row class="navpoint-row">
                        <b-col v-if="false" class="navpoint" :class="{ active: $route.path == '/private/data' }">
                            <router-link :to="{name: 'data'}">
                                <div class="item">
                                    <span class="gfl gf-my-data"></span>
                                    <br/>{{ $t('navigation.data') }}
                                </div>
                            </router-link>
                        </b-col>

                        <b-col v-if="this.$store.state.session.user != null" class="navpoint" :class="{ active: $route.path == '/private/cards' }">
                            <router-link :to="{name: 'cards'}">
                                <div class="item">
                                    <span class="gfl gf-wallet"></span>
                                    <br/>{{ $t('navigation.cards') }}
                                </div>
                            </router-link>
                        </b-col>
                        
                        <b-col v-if="false" class="navpoint" :class="{ active: $route.path == '/private/applications' }">
                            <router-link :to="{name: 'applications'}">
                                <div class="item">
                                    <span class="gfl gf-form"></span>
                                    <br/>{{ $t('navigation.applications') }}
                                </div>
                            </router-link>
                        </b-col>

                        <b-col v-if="this.$store.state.session.user != null" class="navpoint" :class="{ active: $route.path == '/private/change-data' || $route.path == '/private/change-password' }">
                            <router-link :to="{name: 'change-data'}">
                                <div class="item">
                                    <span class="gfl gf-account"></span>
                                    <br/>{{  user_firstname() }}
                                </div>
                            </router-link>
                        </b-col>
                        
                    </b-row>
                </b-col>
                <b-col v-if="false" cols="4" md="3" lg="3" sm="6" class="usermenu-container">
                    <div class="usermenu">
                        <span class="gfl gf-account"></span>
                        <br/>
                        {{ user_firstname() }}
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div class="lower-line"></div>
    </div>
</template>

<script>
import { LOGOUT } from "@st/session/actions.type"
import router from "@/router";
import store from "@st"

export default {
  name: "Navbar",
  props: {
      isLoggedIn: Boolean,
  },
  methods: {
      async logout(){
        await this.$store.dispatch(`session/${LOGOUT}`)
      },
      toLogin(){
        router.push({ name: 'cards'})
      },
      change_password(){
        router.push({ name: 'change-password'})
      },
      user_firstname(){
          let user = store.getters['session/user']
          return user.first_name;
      },
      user_lastname(){
          let user = store.getters['session/user']
          return user.last_name;
      },
      user_fullname(){
          let user = store.getters['session/user']
          return user.full_name;
      },
      user_id(){
          let user = store.getters['session/user']
          return user.id;
      },
      user_idbc(){
          let user = store.getters['session/user']
          return user.id_bc;
      }
  },
}
</script>

<style>

</style>