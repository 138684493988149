var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"g-btn",class:{
    'gbtn-longer':this.icon, 
    'gbtn-grey': (this.color == 'grey' || this.color == 'gray') ,
    'gbtn-orange': this.color == 'orange',
    'gbtn-red': this.color == 'red',
    'gbtn-block': this.block,
    'gbtn-smaller': this.smaller
  },attrs:{"type":_vm.type},on:{"click":function($event){return _vm.emitClick()}}},[_vm._v(_vm._s(this.title)+" "),(this.icon)?_c('span',{staticClass:"gfl",class:this.icon}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }