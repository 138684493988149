<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="8" lg="8" xl="6"
        offset="0" offset-sm="0" offset-md="2" offset-lg="2" offset-xl="3"
      > 
        <div class="form-box">
          <form action="" @submit.prevent="register()">
          <h1>{{$t('pages.register.title')}}</h1>
          <small>{{$t('pages.register.subtext')}}</small>
          <br><br>
          <b-row>
            <b-col cols="6">
              <select v-model="anrede" style="width:100%">
                <option value="17D61A24130">{{ $t('general.salutation.mr')}}</option>
                <option value="17D61A2526C">{{ $t('general.salutation.mrs')}}</option>
                <option value="17D61A2686E">{{ $t('general.salutation.na')}}</option>
              </select>
            </b-col>
            <b-col cols="12"></b-col>
            <b-col cols="12" sm="12" md="6" xl="6">
              <input v-model="vorname" style="width:100%;" type="text" :placeholder="$t('pages.register.name_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="6" xl="6">
              <input v-model="name" style="width:100%;"  type="text" :placeholder="$t('pages.register.surname_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="12" xl="12">
              <input v-model="email" style="width:100%;"  type="text" :placeholder="$t('pages.register.email_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="12" xl="12">
              {{ $t('pages.register.pw_requirements')}}
            </b-col>
            <b-col cols="12" sm="12" md="12" xl="12">
              <input v-model="pw" style="width:100%;"  type="password" :placeholder="$t('pages.register.pw_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="12" xl="12">
              <input v-model="pwrepeat" style="width:100%;"  type="password" :placeholder="$t('pages.register.pw_repeat_placeholder')"/>
            </b-col>
          </b-row>
          <br>
          <div v-if="this.$store.state.session.registerError != null" class="info-box" style="margin-bottom:20px;">
            {{ $t('pages.register.error_register') }}
          </div>
          <div style="text-align:right">
            <gbtn
              type="submit"
              :title="!this.$store.state.session.registering ? $t('pages.register.btn_register'): $t('general.btn_processing')"
              :color="!this.$store.state.session.registering ? 'orange': 'black'"
              :block="false"
              :smaller="true"
              />
          </div>
          <br>
          {{$t('pages.register.known_account')}}
          <router-link :to="{name: 'login'}">
            <span class="marked">{{$t('pages.register.known_account_login')}}</span>
          </router-link>
          </form>
        </div>
      </b-col>
    </b-row>
    <b-modal :visible="this.$store.state.session.registered" id="modal_registerSuccess" :title="$t('pages.register.popup_success_title')">
      <p>{{ $t('pages.register.popup_success_subtext') }}</p>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <gbtn :title="$t('pages.register.popup_success_btn')" @click="toLogin()" color="orange" :block="true" :smaller="true"/>
        </b-col>
      </b-row>
      <template #modal-footer><div></div></template>
    </b-modal>
  </div>
</template>

<script>
import gbtn from '@c/GButton.vue'
import { REGISTER, RESET_VALUES_FN } from "@st/session/actions.type"
import router from "@/router";

export default {
  name: "Register",
  components: {gbtn},  
  data() {
    return {
      anrede: "17D61A24130",
      vorname: null,
      name: null,
      email: null,
      pw: null,
      pwrepeat: null
    }
  },
  methods: {
    async toLogin(){
      this.anrede = "17D61A24130";
      this.vorname = null;
      this.name = null;
      this.email = null;
      this.pw = null;
      this.pwrepeat = null;
      await this.$store.dispatch(`session/${RESET_VALUES_FN}`);
      router.push({ name: 'login' })
    },
    async register() {
      let lng = document.querySelector('html').getAttribute('lang')
      await this.$store.dispatch(`session/${REGISTER}`,[ this.anrede, this.email.toLowerCase(), this.name, this.vorname, this.pw, this.pwrepeat, lng ])
    }
  }
}
</script>

<style>
</style>