import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from "lodash";
import publicRoutes from "./public";
import privateRoutes from "./private";
//import E404 from "@v/public/E404.vue"

Vue.use(VueRouter)

const routes = _.flatten( [
  privateRoutes,
  publicRoutes,
  [
    {
      path: "/",
      name: "index"
    }
  ]
]);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
