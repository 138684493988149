
<template>
  <router-view/>
</template>

<script>
import store from "@st"
import router from "@/router";
/* eslint-disable no-undef */
export default {
  name: "Private",
  beforeRouteEnter (to, from, next) {
    if(!store.getters['session/isLoggedIn']) {
      let route = window.location.href
      let nextRoute = router.resolve({ name: 'login', query: { dest_url: route}})
      next(nextRoute.route)
    } else next()
  }
}
</script>

<style>

</style>