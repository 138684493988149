<template>
  <div>
    <b-row>
      <b-col><h1>{{ $t('pages.settings.title') }}</h1></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="4" xl="3">
        <navbarsettings/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="8" xl="8"
        offset="0" offset-sm="0" offset-md="0" offset-lg="0" offset-xl="1">
        <form action="" @submit.prevent="change_pw()">
        <h2>{{ $t('pages.settings.password.title') }}</h2>
        <p>
          {{ $t('pages.settings.password.subtext') }}
        </p>
        <b-row>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="currpw" type="password" style="width:100%;" :placeholder="$t('pages.settings.password.currpw_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12"></b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="newpw" style="width:100%;" type="password" :placeholder="$t('pages.settings.password.newpw_placeholder')"/>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="newpw_wdh" style="width:100%;"  type="password" :placeholder="$t('pages.settings.password.newpw_repeat_placeholder')"/>
            </b-col>
        </b-row>
        <b-row v-if="api_error">
            <b-col cols="12">
              <div class="info-box">{{ $t('pages.settings.password.error') }}</div>
            </b-col>
        </b-row>
        <b-row v-if="api_success">
            <b-col cols="12">
              <div class="info-box">{{ $t('pages.settings.password.success') }}</div>
            </b-col>
        </b-row>
        <b-row>
            <b-col style="text-align:right;">
              <gbtn
                type="submit"
                :title="!api_load ? $t('pages.settings.password.btn_save') : $t('general.btn_processing')"
                :color="!api_load ? 'orange': 'black'"
                :block="false"
                :smaller="true"
                :disabled="api_load"
              />
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import navbarsettings from '@c/Navbar_Settings.vue'
import gbtn from '@c/GButton.vue'
import api from "@a/index";

export default {
  name: "PasswordChange",
  components: {gbtn, navbarsettings},
  data() {
    return {
      currpw: null,
      newpw: null,
      newpw_wdh: null,
      api_error: false,
      api_success: false,
      api_load: false
    }
  },
  methods: {
    async change_pw(){
      if (
          !this.currpw || 
          !this.newpw || 
          !this.newpw_wdh || 
          (this.newpw != this.newpw_wdh) ||
          this.newpw.length < 8
          ) {
        this.api_error = true;
        this.api_load = false;
        return;
      }
      this.api_error = false;
      this.api_success = false;
      this.api_load = true;
      try {
        let response = await api.user.changePassword(this.currpw, this.newpw, this.newpw_wdh)
        if (response.data.code != 0) {
          this.api_error = true;
          this.api_success = false;
        } else {
          this.api_success = true;
          this.api_error = false;
        }
      } catch(e) {
        console.log(e);
        this.api_error = true;
        this.api_success = false;
      } finally {
        this.api_load = false
      }
    }
  }
}
</script>

<style>

</style>
