<template>
  <div>
    <b-row>
      <b-col><h1>{{ $t('pages.mycards.title') }}</h1></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="4" xl="3">
        <div class="sidebar-box">
          <b-row class="sidebar-items">
            <b-col cols="12" class="active">
              <a href="#"><div>{{ $t('pages.mycards.gera_pass') }} ({{ anzahl_karten }})</div></a>
            </b-col>
          </b-row>
        </div>
      </b-col>


      <b-col cols="12" sm="12" md="12" lg="8" xl="8"
        offset="0" offset-sm="0" offset-md="0" offset-lg="0" offset-xl="1">
        <h2>{{ $t('pages.mycards.gera_pass') }} </h2>
        <p>
          {{ $t('pages.mycards.subtext') }}
        </p>
        <br/>
        <gbtn :title="$t('pages.mycards.btn_pass_add')" icon="gf-plus" color="grey" @click="btn_add()"/>
        <br/><br/>
        <b-row v-if="this.$store.state.session.user.cards != null">
          <!-- ------------------------------------------------ -->
            <b-col v-for="(item, key, index) in this.$store.state.session.user.cards" :key="key" cols="12" sm="12" md="6" lg="6" xl="4">
              <pass :topinfo="item.eigene_karte?(item.freigaben_anzahl==0?'':'für '+item.freigaben_anzahl+' Person(en) freigegeben'):''" :name="item.vorname + ' ' + item.name" :birthday="item.geburtsdatum" :validated="item.validated" :valid="item.valid" :isvalid="item.isvalid" :qrcode="item.qrcode"></pass>
              <div :data-index="index" class="gera-card-buttons">
                <gbtn
                  v-if="item.eigene_karte"
                  :title="$t('pages.mycards.btn_pass_share')"
                  color="orange"
                  :block="true"
                  :smaller="true"
                  @click="btn_share(item.id)"
                  style="margin-bottom:10px;"
                />
                <gbtn
                  v-if="item.eigene_karte && item.freigaben_anzahl > 0"
                  :title="$t('pages.mycards.btn_pass_share_remove')"
                  color="orange"
                  :block="true"
                  :smaller="true"
                  @click="btn_shareRemove(item.id)"
                  style="margin-bottom:10px;"
                />
                <gbtn
                  v-if="item.eigene_karte"
                  :title="$t('pages.mycards.btn_pass_remove')"
                  color="red"
                  :block="true"
                  :smaller="true"
                  @click="btn_remove(item.id)"
                />

                <div v-if="!item.eigene_karte">{{ $t('pages.mycards.no_functions') }}</div>
              </div>
            </b-col>
          <!-- ------------------------------------------------ -->
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="modal_addPass" :title="$t('pages.mycards.popup_pass_add_title')">
      <form action="" @submit.prevent="card_add()">
      <p>{{ $t('pages.mycards.popup_pass_add_subtext') }}</p>
      <b-row v-if="api_vars.add_error"><b-col><div class="info-box">{{$t("pages.mycards.popup_pass_add_error")}}</div></b-col></b-row>
      <b-row>
        <b-col cols="12" sm="6">
            <input v-model="c_add" type="text" style="width:100%;" :placeholder="$t('pages.mycards.popup_pass_add_code_placeholder')">
        </b-col>
        <b-col cols="12" sm="6">
            <gbtn
              type="submit"
              :title="!api_vars.add_load ? $t('pages.mycards.popup_pass_add_btn') : $t('general.btn_processing')"
              :color="!api_vars.add_load ? 'orange': 'black'"
              :block="true"
              :smaller="true"
              :disabled="api_vars.add_load"
            />
        </b-col>
      </b-row>
      </form>
      <template #modal-footer><div></div></template>
    </b-modal>
    <b-modal id="modal_sharePass" :title="$t('pages.mycards.popup_pass_share_title')">
      <form action="" @submit.prevent="card_share()">
      <p>{{ $t('pages.mycards.popup_pass_share_subtext') }}</p>
      <b-row v-if="api_vars.share_error"><b-col><div class="info-box">{{$t("pages.mycards.popup_pass_share_error")}}</div></b-col></b-row>
      <b-row>
        <b-col>
          <input v-model="c_share_mail" type="text" style="width:100%;" :placeholder="$t('pages.mycards.popup_pass_share_email_placeholder')">
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-datepicker
            size="lg"
            :placeholder="$t('pages.mycards.popup_pass_share_date_placeholder')" 
            :min="new Date()" id="sharedatepicker"
            v-model="c_share_date"
            class="mb-2"
            locale="de"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            :hide-header="true"
            :show-decade-nav="false"
            >
          </b-form-datepicker>
          <input v-if="false" v-model="c_share_date" type="text" style="width:100%;" :placeholder="$t('pages.mycards.popup_pass_share_date_placeholder')">
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <gbtn 
            type="submit"
            :title="!api_vars.share_load ? $t('pages.mycards.popup_pass_share_btn') : $t('general.btn_processing')"
            :color="!api_vars.share_load ? 'orange': 'black'"
            :block="true"
            :smaller="true"
            :disabled="api_vars.share_load"
          />
        </b-col>
      </b-row>
      </form>
      <template #modal-footer><div></div></template>
    </b-modal>
    <b-modal id="modal_removePass" :title="$t('pages.mycards.popup_pass_remove_title')">
      <p>{{ $t('pages.mycards.popup_pass_remove_subtext') }}</p>
      <b-row v-if="api_vars.remove_error"><b-col><div class="info-box">{{$t("pages.mycards.popup_pass_remove_error")}}</div></b-col></b-row>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <gbtn
            @click="card_remove()"
            :title="!api_vars.remove_load ? $t('pages.mycards.popup_pass_remove_btn') : $t('general.btn_processing')"
            :color="!api_vars.remove_load ? 'red': 'black'"
            :block="true"
            :smaller="true"
            :disabled="api_vars.remove_load"
          />
        </b-col>
      </b-row>
      <template #modal-footer><div></div></template>
    </b-modal>
    <b-modal id="modal_removePassAccess" :title="$t('pages.mycards.popup_pass_shareremove_title')">
      <p>{{ $t('pages.mycards.popup_pass_shareremove_subtext') }}</p>
      <b-row v-if="api_vars.removeshare_error"><b-col><div class="info-box">{{$t("pages.mycards.popup_pass_shareremove_error")}}</div></b-col></b-row>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <gbtn 
            @click="card_removeShare()"
            :title="!api_vars.removeshare_load ? $t('pages.mycards.popup_pass_shareremove_btn') : $t('general.btn_processing')"
            :color="!api_vars.removeshare_load ? 'orange': 'black'"
            :block="true"
            :smaller="true"
            :disabled="api_vars.removeshare_load"
          />
        </b-col>
      </b-row>
      <template #modal-footer><div></div></template>
    </b-modal>
  </div>
</template>

<script>
import pass from '@c/Pass.vue'
import gbtn from '@c/GButton.vue'
import api from "@a/index"

export default {
  name: "MyCards",
  components: {pass,gbtn},
  data() {
    return {
      c_add: null,
      c_remove: null,
      c_share: null,
      c_share_date: null,
      c_share_mail: null,
      c_removeshare: null,
      api_vars: {
        add_error: false,
        add_load: false,
        remove_error: false,
        remove_load: false,
        share_error: false,
        share_load: false,
        removeshare_error: false,
        removeshare_load: false
      }
    }
  },
  computed: {
    anzahl_karten: function () {
      return this.$store.state.session.user.cards.filter(function(){return true;}).length;
    }
  },
  methods: {
    btn_add(){
      this.c_add = "";
      this.api_vars.add_error = false;
      this.api_vars.add_load = false;
      this.$bvModal.show('modal_addPass');
    },
    btn_share(val){
      this.c_share = val;
      this.c_share_date = "";
      this.c_share_mail = "";
      this.api_vars.share_error = false;
      this.api_vars.share_load = false;
      this.$bvModal.show('modal_sharePass');
    },
    btn_shareRemove(val){
      this.c_removeshare = val;
      this.api_vars.removeshare_error = false;
      this.api_vars.removeshare_load = false;
      this.$bvModal.show('modal_removePassAccess');
    },
    btn_remove(val){
      this.c_remove = val;
      this.api_vars.remove_error = false;
      this.api_vars.remove_load = false;
      this.$bvModal.show('modal_removePass');
    },
    async card_add(){
      if (!this.c_add || this.c_add.length < 4) {
        this.api_vars.add_error = true;
        this.api_vars.add_load = false;
        return;
      }

      this.api_vars.add_error = false;
      this.api_vars.add_load = true;
      
      try {
        let response = await api.card.add(this.c_add)
        if (response.data.code != 0) {
          this.api_vars.add_error = true;
        } else {
          await api.user.getCurrent();
          location.reload();
        }
      } catch(e) {
        this.api_vars.add_error = true;
        console.log(e)
      } finally {
        this.api_vars.add_load = false;
      }
    },
    async card_remove(){
      this.api_vars.remove_error = false;
      this.api_vars.remove_load = true;

      try {
        let response = await api.card.remove(this.c_remove)
        if (response.data.code != 0) {
          this.api_vars.remove_error = true;
        } else {
          await api.user.getCurrent();
          location.reload();
        }
      } catch(e) {
        console.log(e)
        this.api_vars.remove_error = true;
      } finally {
        this.api_vars.remove_load = false;
      }
    },
    async card_share(){
      if (!this.c_share_mail || this.c_share_mail.length < 7 || this.c_share_mail.indexOf('@') == -1 || this.c_share_mail.indexOf('.') == -1) {
        this.api_vars.share_error = true;
        this.api_vars.share_load = false;
        return;
      }

      this.api_vars.share_error = false;
      this.api_vars.share_load = true;

      try {
        let response = await api.card.share(this.c_share, this.c_share_mail, this.c_share_date)
        if (response.data.code != 0) {
          this.api_vars.share_error = true;
        } else {
          await api.user.getCurrent();
          location.reload();
        }
      } catch(e) {
        console.log(e)
        this.api_vars.share_error = true;
      } finally {
        this.api_vars.share_load = false;
      }
    },
    async card_removeShare(){
      this.api_vars.removeshare_error = false;
      this.api_vars.removeshare_load = true;

      try {
        let response = await api.card.shareRemove(this.c_removeshare)
        if (response.data.code != 0) {
          this.api_vars.removeshare_error = true;
        } else {
          await api.user.getCurrent();
          location.reload();
        }
        this.api_vars.removeshare_load = false;
      } catch(e) {

        console.log(e)
        this.api_vars.removeshare_error = true;
      } finally {
        this.api_vars.removeshare_load = false;
      }
    },
  }
}
</script>

<style>

</style>
