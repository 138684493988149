<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="8" lg="8" xl="8"
        offset="0" offset-sm="0" offset-md="2" offset-lg="2" offset-xl="2"
      >
        <div class="form-box">
          <form action="" @submit.prevent="login()">
          <h1>{{ $t('pages.login.title') }}</h1>
          <small>{{ $t('pages.login.subtext') }}</small>
          <br><br>
          <input v-model="username" type="text" :placeholder="$t('pages.login.btn_email_placeholder')">
          <input v-model="password" type="password" :placeholder="$t('pages.login.btn_password_placeholder')">
          <div v-if="$store.state.session.loginError != null" class="info-box" style="margin-bottom:20px;">
            {{ $t('pages.login.error_login') }}
          </div>
          <div style="text-align:right">
            <gbtn
              type="submit"
              :title="!loggingIn ? $t('pages.login.btn_login'): $t('general.btn_processing')"
              :color="!loggingIn ? 'orange': 'black'"
              :disabled="loggingIn"
              :block="false"
              :smaller="true"
            />
          </div>
          </form>
          <br>
          {{ $t('pages.login.no_account')}}
          <router-link :to="{name: 'register'}">
            <span class="marked">{{ $t('pages.login.no_account_register')}}</span>
          </router-link>
          <br/><br/>

          <div>{{ $t('pages.login.pw_forgotten')}}
          <router-link :to="{name: 'restore'}">
            <span class="marked">{{ $t('pages.login.pw_forgotten_restore')}}</span>
          </router-link>
          </div>
          
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gbtn from '@c/GButton.vue'
import { LOGIN } from "@st/session/actions.type"

export default {
  name: "Login",
  components: {gbtn},
  props: {
    destUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      username: null,
      password: null,
      processing: false,

    }
  },
  computed: {
    loggingIn() {
      return this.$store.state.session.loggingIn;
    }
  },
  methods: {
    async login() {
      await this.$store.dispatch(`session/${LOGIN}`, [ this.username.toLowerCase(), this.password ])
    }
  }
}
</script>

<style>
</style>
