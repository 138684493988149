import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  auth: (process.env.NODE_ENV === "development" && process.env.VUE_APP_OVERRIDE === "1")? {
    username: process.env.VUE_APP_USERNAME,
    password: process.env.VUE_APP_PASSWORD
  } : null
})

export default api;
