import Public from "@v/public/Index.vue";
import Login from "@v/public/session/Login.vue"
import Register from "@v/public/session/Register.vue"
import RegisterFinish from "@v/public/session/RegisterFinish.vue"
import Restore from "@v/public/session/PasswordReset.vue"


export default [
  {
    path: "/public",
    name: "public",
    component: Public,
    children: [
      {
        path: "session/login",
        name: "login",
        component: Login,
        props: route => {
          let destUrl = route.query.dest_url;
          return {
            destUrl
          }
        }
      },
      {
        path: "session/register",
        name: "register",
        component: Register,
      },
      {
        path: "session/registerfinish/:token",
        name: "registerfinish",
        component: RegisterFinish,
      },
      {
        path: "session/restore",
        name: "restore",
        component: Restore,
      },
      {
        path: "session/restore/:token",
        name: "restoretoken",
        component: Restore,
      }
    ]
  }
]
