<template>
  <div class="sidebar-box">
    <b-row class="sidebar-items">
      <b-col cols="12" :class="{ active: $route.path == '/private/change-data' }">
        <router-link :to="{name: 'change-data'}">
          <div>{{ $t("navigation.personal_data") }}</div>
        </router-link>
      </b-col>
    </b-row>
    <b-row class="sidebar-items">
      <b-col cols="12" :class="{ active: $route.path == '/private/change-password' }">
        <router-link :to="{name: 'change-password'}">
          <div>{{ $t("navigation.change_password") }}</div>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hr/>
      </b-col>
    </b-row>
    <b-row class="sidebar-items">
      <b-col cols="12">
        <a href="#" @click="logout()">
          <div>{{ $t("navigation.logout") }}</div>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { LOGOUT } from "@st/session/actions.type"

export default {
  name: 'NavbarSettings',
  methods: {
      async logout(){
        await this.$store.dispatch(`session/${LOGOUT}`)
      },
  },
}
</script>

<style>

</style>