<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="8" lg="8" xl="8"
        offset="0" offset-sm="0" offset-md="2" offset-lg="2" offset-xl="2"
      > 
        <div class="form-box" v-if="!token">
          <form action="" @submit.prevent="request_reset()">
          <h1>{{ $t('pages.passwordreset.title') }}</h1>
          {{ $t('pages.passwordreset.subtext') }}
          <br><br>
          <input v-model="username" type="text" :placeholder="$t('pages.passwordreset.email_placeholder')">
          
          <div v-if="api_error" class="info-box">
            {{ $t('pages.passwordreset.error') }}
          </div>
          <div v-if="api_requested" class="info-box">
            {{ $t('pages.passwordreset.success') }}
          </div>
          <br>
          <div style="text-align:right"><gbtn type="submit" :title="$t('pages.passwordreset.btn_send')" color="orange" :block="false" :smaller="true"/></div>
          <br>
          {{ $t('pages.passwordreset.known_account') }}
          <router-link :to="{name: 'login'}">
            <span class="marked">{{ $t('pages.passwordreset.known_account_login') }}</span>
          </router-link>
          </form>
        </div>

        <div class="form-box" v-if="token">
          <form action="" @submit.prevent="save()">
          <h1>{{ $t('pages.passwordresetfinish.title') }}</h1>
          {{ $t('pages.passwordresetfinish.subtext') }}
          <br><br>
          <input v-model="pw1" type="password" :placeholder="$t('pages.register.pw_placeholder')">
          <input v-model="pw2" type="password" :placeholder="$t('pages.register.pw_repeat_placeholder')">
          
          <div v-if="api_error" class="info-box">
            {{ $t('pages.passwordresetfinish.error') }}
          </div>
          <br>
          <div style="text-align:right"><gbtn type="submit" :title="$t('pages.passwordresetfinish.btn_save')" color="orange" :block="false" :smaller="true"/></div>
          </form>
        </div>

      </b-col>
    </b-row>
    <b-modal id="modal_successChange" :title="$t('pages.passwordresetfinish.popup_success_title')">
      <p>{{ $t('pages.passwordresetfinish.popup_success_subtext') }}</p>
      <b-row>
        <b-col>
        </b-col>
        <b-col>
            <gbtn
              @click="toLogin()"
              :title="$t('pages.passwordresetfinish.popup_btn_tologin')"
              color="orange"
              :block="true"
              :smaller="true"
            />
        </b-col>
      </b-row>
      <template #modal-footer><div></div></template>
    </b-modal>
  </div>
</template>

<script>
import gbtn from '@c/GButton.vue'
import { PWRESET, PWRESET_REQUEST, RESET_VALUES_FN } from "@st/session/actions.type"
import router from "@/router";

export default {
  name: "PasswordReset",
  components: {gbtn},  
  data() {
    return {
      username: null,
      pw1: null,
      pw2: null,
      api_error: null,
      api_requested: null,
      token: null
    }
  },
  async mounted() {
    this.token = this.$route.params.token;
  },
  methods: {
    async toLogin(){
      this.username = null;
      this.pw1 = null;
      this.pw2 = null;
      this.api_error  = null;
      this.api_requested = null;
      this.token = null;
      await this.$store.dispatch(`session/${RESET_VALUES_FN}`);
      router.push({ name: 'login' })
    },
    async save(){
      //this.api_requested = false;
      if (!this.pw1 || !this.pw2 || this.pw1.length < 8 || this.pw1 != this.pw2) {
        this.api_error = true;
        return;
      }

      //this.api_error = false;
      //this.api_requested = true;

      let response = await this.$store.dispatch(`session/${PWRESET}`, [this.token, this.pw1, this.pw2]);
      console.log(response);
      if (response.data.code != 0) {
        this.api_error = true
      } else {
        this.api_error = false
        this.$bvModal.show('modal_successChange');
      }
    },
    async request_reset(){
      this.api_requested = false;
      if (!this.username || this.username.length < 7 || this.username.indexOf("@") == -1 || this.username.indexOf(".") == -1) {
        this.api_error = true;
        return;
      }

      this.api_error = false;
      this.api_requested = true;
      let lng = document.querySelector('html').getAttribute('lang')

      await this.$store.dispatch(`session/${PWRESET_REQUEST}`, [this.username, lng]);
    },
  }
}
</script>

<style>
</style>