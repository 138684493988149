<template>
  <div>
    <b-row>
      <b-col cols="12" sm="12" md="8" lg="8" xl="8"
        offset="0" offset-sm="0" offset-md="2" offset-lg="2" offset-xl="2"
      > 
        <div class="form-box">
          <form action="" @submit.prevent="registerfinish()">
          <div v-if="worked === null">
            {{ $t('general.btn_processing') }}
          </div>
          <div v-if="worked === true">
            <h3>{{ $t('pages.registerfinish.success_title') }}</h3>
            <br/>
            {{ $t('pages.registerfinish.success_subtext') }}
          </div>
          <div v-if="worked === false">
            <h3>{{ $t('pages.registerfinish.error_title') }}</h3>
            <br/>
            {{ $t('pages.registerfinish.error_subtext') }}
          </div>
          <br>
          <br>
          <div style="text-align:right">
            <gbtn
              type="submit"
              v-if="worked != null"
              :title="$t('pages.register.popup_success_btn')"
              :smaller="true"
              color="orange"
              :block="false"
            />
          </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gbtn from '@c/GButton.vue'
import router from '@/router'
import store from "@st"
import { REGISTER_FINISH , RESET_VALUES_FN} from "@st/session/actions.type"

export default {
  name: "RegisterFinish",
  // eslint-disable-next-line vue/no-unused-components
  components: {gbtn,store},
  data() {
    return {
      api_error: null,
      api_success: null,
      api_load: false,
      worked: null
    }
  },
  async mounted() {
    this.$nextTick(async function () {
      await this.$store.dispatch(`session/${REGISTER_FINISH}`, [this.$route.params.token]);
      this.worked = this.$store.state.session.reg_finished;
    })
  },
  methods: {
    async registerfinish() {
      this.api_error = null;
      this.api_success = null;
      this.api_load = false;
      this.worked = null;
      await this.$store.dispatch(`session/${RESET_VALUES_FN}`);
      router.push({name:'login'})
    }
  }
}
</script>

<style>
</style>