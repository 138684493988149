import Private from "@v/private/Index.vue";
import MyCards from "@v/private/user/MyCards.vue";
import Password from "@v/private/user/settings/Password.vue";
import Personal from "@v/private/user/settings/Personal.vue"

export default [
  {
    path: "/private",
    name: "private",
    component: Private,
    children: [
      {
        path: "cards",
        name: "cards",
        component: MyCards
      },
      {
        path: "change-password",
        name: "change-password",
        component: Password
      },
      {
        path: "change-data",
        name: "change-data",
        component: Personal
      }
    ]
  }
]

