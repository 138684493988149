import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import api from "@a/index";

import { LOGIN, LOGOUT, LOAD_USER, REGISTER, REGISTER_FINISH, PWRESET, PWRESET_REQUEST, RESET_VALUES_FN } from "./actions.type"
import { LOGIN_START, LOGIN_STOP, UPDATE_USER, REGISTER_START, REGISTER_STOP, REGISTER_UPDATE, REGISTERED, REGISTER_FINISHED_ERROR, REGISTER_FINISHED_SUCCESS, RESET_FINISHED_ERROR, RESET_FINISHED_SUCCESS, RESET_VALUES} from "./mutations.type"
import router from "@/router";

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    loggingIn: false,
    loginError: null,
    user: null,
    registering: null,
    registerError: null,
    registered: false,
    reg_finished: null,
    reset_finished: null
  },
  mutations: {
    [LOGIN_START]: (state) => {
      state.loggingIn = true
      state.loginError = null
    },
    [LOGIN_STOP]: (state, errorMessage) => {
      state.loggingIn = false
      state.loginError = errorMessage
    },
    [UPDATE_USER]: (state, val) => {
      state.user = val;
    },
    [REGISTERED]: (state) => {
      state.registered = true
      state.loginError = null
    },
    [REGISTER_START]: (state) => {
        state.registering = true
        state.registered = false
        state.loginError = null
    },
    [REGISTER_STOP]: (state) => {
        state.registering = false
        state.registered = false
        state.loginError = null
    },
    [REGISTER_UPDATE]: (state, val) => {
      state.loginError = null
      state.registered = false
      state.registerError = val
    },
    [REGISTER_FINISHED_ERROR]: state => state.reg_finished = false,
    [REGISTER_FINISHED_SUCCESS]: state => state.reg_finished = true,
    [RESET_FINISHED_ERROR]: state => state.reset_finished = false,
    [RESET_FINISHED_SUCCESS]: state => state.reset_finished = true,
    [RESET_VALUES]: (state) => {
      state.loggingIn = false,
      state.loginError = null,
      state.user = null,
      state.registering = null,
      state.registerError = null,
      state.registered = false,
      state.reg_finished = null,
      state.reset_finished = null
    },
  },
  actions: {
    async [RESET_VALUES_FN] (context) {
      context.commit(RESET_VALUES, null);
    },
    async [LOGIN] (context, [username, password]) {
      let params = new URLSearchParams()
      params.append("username", username)
      params.append("password", password)

      let loginUrl = process.env.VUE_APP_LOGIN_URL;

      context.commit(LOGIN_STOP, null);
      context.commit(LOGIN_START, null);
      let response_user = await axios.post(loginUrl, params).catch(function(){
        context.commit(LOGIN_STOP, 'ERR01');
      });

      context.commit(UPDATE_USER, response_user.data);
      context.commit(LOGIN_STOP, null);
      router.push({ name: 'cards' });
    },
    async [LOGOUT]({ commit }) {
      await axios.post(process.env.VUE_APP_LOGOUT_URL);
      commit(RESET_VALUES_FN, null);
      commit(UPDATE_USER, null);
      router.push({ name: 'login' });
    },
    async [REGISTER] (context, [anrede,email,name,vorname,pass,passwdh,lng]) {
      let params = new URLSearchParams()
      params.append("anrede", anrede)
      params.append("email", email)
      params.append("name", name)
      params.append("vorname", vorname)
      params.append("pass", pass)
      params.append("passwdh", passwdh)
      params.append("lng", lng)
      
      let registerUrl = process.env.VUE_APP_REGISTER_URL;

      context.commit(REGISTER_START);
      context.commit(REGISTER_UPDATE, null);
      let response_register = await axios.post(registerUrl, params).catch(function(){
        context.commit(REGISTER_UPDATE, 99);
        context.commit(REGISTER_STOP);
      });
      context.commit(REGISTER_STOP);

      if (response_register.data.code != 0) {
        context.commit(REGISTER_UPDATE, response_register.data.code);
      } else {
        context.commit(REGISTERED);
      }
    },
    async [REGISTER_FINISH] (context, [token]) {
      let params = new URLSearchParams()
      params.append("token", token)
      
      let registerUrl = process.env.VUE_APP_REGISTER_FINISH_URL;
      let response_register = await axios.post(registerUrl, params).catch(function(){
        context.commit(REGISTER_FINISHED_ERROR);
      });

      if (response_register.data.code != 0) {
        context.commit(REGISTER_FINISHED_ERROR);
      } else {
        context.commit(REGISTER_FINISHED_SUCCESS);
      }
    },
    async [PWRESET] (context, [token, pw1, pw2]) {
      let params = new URLSearchParams()
      params.append("token", token)
      params.append("pw1", pw1)
      params.append("pw2", pw2)
      
      let resetUrl = process.env.VUE_APP_PW_RESET_URL;

      let response_pwreset = await axios.post(resetUrl, params).catch(function(){
        context.commit(RESET_FINISHED_ERROR);
      });

      if (response_pwreset.data.code != 0) {
        context.commit(RESET_FINISHED_ERROR);
      } else {
        context.commit(RESET_FINISHED_SUCCESS);
      }

      return response_pwreset
    },
    async [PWRESET_REQUEST] (context, [username, lng]) {
      let params = new URLSearchParams()
      params.append("username", username)
      params.append("lng", lng)
      
      let resetRequestUrl = process.env.VUE_APP_PW_RESET_REQUEST_URL;

      await axios.post(resetRequestUrl, params).catch(function(e){
        console.log(e);
      });
    },
    async [LOAD_USER]({ commit }) {
      let response = await api.user.getCurrent();
      commit(UPDATE_USER, response.data);
    }
  },
  getters: {
    isLoggedIn: state => state.user !== null,
    user: state => state.user,
    registerError: state => state.registerError !== null,
    loginError: state => state.loginError
  }
}

