<template>
  <div>
    <b-row>
      <b-col><h1>{{ $t('pages.settings.title') }}</h1></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="4" xl="3">
        <navbarsettings/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="8" xl="8" 
        offset="0" offset-sm="0" offset-md="0" offset-lg="0" offset-xl="1">
        <form action="" @submit.prevent="change_data()">
        <h2>{{ $t('pages.settings.personal.title')}}</h2>
        <p>
          {{ $t('pages.settings.personal.subtext')}}
        </p>
        <b-row>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6">
              <select v-model="anrede" style="width:100%">
                <option value="17D61A24130">{{ $t('general.salutation.mr')}}</option>
                <option value="17D61A2526C">{{ $t('general.salutation.mrs')}}</option>
                <option value="17D61A2686E">{{ $t('general.salutation.na')}}</option>
              </select>
            </b-col>
            <b-col  cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="email" readonly disabled style="width:100%;"  type="text"/>
            </b-col>
            <b-col  cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="vorname" style="width:100%;" type="text" :placeholder="$t('pages.settings.personal.name_placeholder')"/>
            </b-col>
            <b-col  cols="12" sm="12" md="6" lg="6" xl="6">
              <input v-model="nachname" style="width:100%;"  type="text" :placeholder="$t('pages.settings.personal.surname_placeholder')"/>
            </b-col>
        </b-row>
        <b-row v-if="api_error">
            <b-col cols="12">
              <div class="info-box">{{ $t('pages.settings.personal.error') }}</div>
            </b-col>
        </b-row>
        <b-row v-if="api_success">
            <b-col cols="12">
              <div class="info-box">{{ $t('pages.settings.personal.success') }}</div>
            </b-col>
        </b-row>
        <b-row>
            <b-col style="text-align:right;">
              <gbtn
                type="submit"
                :title="!api_load ? $t('pages.settings.personal.btn_save') : $t('general.btn_processing')"
                :color="!api_load ? 'orange': 'black'"
                :block="false"
                :smaller="true"
                :disabled="api_load"
              />
            </b-col>
          </b-row>
        </form>
      </b-col>
    </b-row>

  </div>
</template>          

<script>
import navbarsettings from '@c/Navbar_Settings.vue'
import gbtn from '@c/GButton.vue'
import api from "@a/index";

export default {
  name: "PersonalChange",
  components: {gbtn, navbarsettings},
  data() {
    return {
      anrede: null,
      vorname: null,
      nachname: null,
      email: null,
      api_error: false,
      api_success: false,
      api_load: false
    }
  },
  mounted() {
    this.anrede = this.$store.state.session.user.salutation;
    this.vorname = this.$store.state.session.user.first_name;
    this.nachname = this.$store.state.session.user.last_name;
    this.email = this.$store.state.session.user.email;
  },
  methods: {
    async change_data(){
      if (
          !this.anrede || 
          !this.vorname || 
          !this.nachname || 
          (this.vorname.length < 2)  ||
          (this.nachname.length < 2)
          ) {
        this.api_error = true;
        this.api_load = false;
        return;
      }

      this.api_error = false;
      this.api_success = false;
      this.api_load = true;
      try {
        let response = await api.user.changePersonal(this.anrede, this.vorname, this.nachname)
        if (response.data.code != 0) {
          this.api_error = true;
          this.api_success = false;
        } else {
          api.user.getCurrent();
          this.api_success = true;
          this.api_error = false;
        }
      } catch(e) {
        console.log(e)
        this.api_error = true;
        this.api_success = false;
      } finally {
        this.api_load = false;
      }
    }
  }
}
</script>

<style>

</style>